<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>Фактура</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <div class="form-group">
                    <label for="">
                      <span> Клиент:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="clients"
                        label="name"
                        v-model="invoice.clientId"
                        :value="invoice.clientId"
                        :reduce="clients => clients.id"
                        @input="fetchRoutemaps()"
                      ></vu-select>
                    </div>
                    <div v-if="$v.invoice.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.invoice.clientId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="form-group">
                    <label for="">
                      <span> Дата:</span>
                    </label>
                    <div class="field-wrap">
                      <datetime
                        zone="local"
                        value-zone="local"
                        type="date"
                        v-model="invoice.date"
                        input-class="form-control"
                      ></datetime>
                    </div>
                    <div v-if="$v.invoice.$error">
                      <div class="text-danger" v-if="!$v.invoice.date.required">
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="form-group">
                    <label for="">
                      <span> Номер:</span>
                    </label>
                    <div class="field-wrap">
                      <input v-model="invoice.number" class="form-control" />
                    </div>
                    <div v-if="$v.invoice.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.invoice.number.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" v-if="invoice.clientId > 0">
                  <v-btn
                    @click="showGenerateInvoiceDialog()"
                    class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                    >Генерирай фактура
                  </v-btn>
                </v-col>
              </v-row>
              <hr />
              <template v-if="invoice.details.length > 0">
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(product, i) in invoice.details"
                    v-bind:key="i"
                  >
                    <v-expansion-panel-header>
                      <div class="text-left">
                        <h4>Продукт {{ i + 1 }}</h4>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row v-if="invoice.clientId">
                        <v-col cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Тип продукт:</span>
                            </label>
                            <div class="field-wrap">
                              <vu-select
                                :options="productTypes"
                                label="name"
                                v-model="product.productTypeId"
                                :value="product.productTypeId"
                                :reduce="productTypes => productTypes.id"
                                @input="handleProductTypeChange(product)"
                              ></vu-select>
                            </div>
                          </div>
                        </v-col>
                        <v-col v-if="product.productTypeId == 1" cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Количество:</span>
                            </label>
                            <div class="field-wrap">
                              <input
                                min="1"
                                type="number"
                                class="form-control"
                                v-model="product.quantity"
                              />
                            </div>
                          </div>
                        </v-col>
                        <v-col v-if="product.productTypeId == 2" cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Номер на маршрутна карта:</span>
                            </label>
                            <div class="field-wrap">
                              <vu-select
                                append-to-body
                                label="name"
                                :options="product.freeRouteMaps"
                                :reduce="freeRouteMap => freeRouteMap.id"
                                v-model="product.routeMapId"
                                @input="
                                  filterRouteMaps();
                                  changeRouteMap($event, product);
                                "
                              ></vu-select>
                            </div>
                          </div>
                        </v-col>
                        <v-col v-if="product.productTypeId" cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Цена за една бройка без ДДС:</span>
                            </label>
                            <div class="field-wrap">
                              <input
                                type="number"
                                min="0"
                                class="form-control"
                                v-model="product.price"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12">
                          <v-btn
                            @click="deleteProduct(i)"
                            class="btn btn-danger pull-right btn-elevate kt-login__btn-primary text-white"
                            >{{ $t("BUTTON.DELETE") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
              <v-row v-if="invoice.clientId">
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Тип продукт:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="productTypes"
                        label="name"
                        v-model="newProduct.productTypeId"
                        :value="newProduct.productTypeId"
                        :reduce="productTypes => productTypes.id"
                        @input="handleProductTypeChange(newProduct)"
                      ></vu-select>
                    </div>
                    <div v-if="$v.newProduct.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.newProduct.productTypeId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col v-if="newProduct.productTypeId == 1" cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Количество:</span>
                    </label>
                    <div class="field-wrap">
                      <input
                        min="1"
                        type="number"
                        class="form-control"
                        v-model="newProduct.quantity"
                      />
                    </div>
                    <div v-if="$v.newProduct.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.newProduct.quantity.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col v-if="newProduct.productTypeId == 2" cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Номер на маршрутна карта:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        append-to-body
                        label="name"
                        :options="freeRouteMaps"
                        :reduce="freeRouteMap => freeRouteMap.id"
                        v-model="newProduct.routeMapId"
                        @input="changeRouteMap($event, newProduct)"
                      ></vu-select>
                    </div>
                    <div v-if="$v.newProduct.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.newProduct.routeMapId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col v-if="newProduct.productTypeId" cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Цена за една бройка без ДДС:</span>
                    </label>
                    <div class="field-wrap">
                      <input
                        type="number"
                        min="0"
                        class="form-control"
                        v-model="newProduct.price"
                      />
                    </div>
                    <div v-if="$v.newProduct.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.newProduct.price.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    @click="addNewProduct()"
                    class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                    >Добави запис
                  </v-btn>
                </v-col>
              </v-row>
              <hr />
              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-dialog v-model="generateInvoiceDialog.display" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Генериране на фактура
        </v-card-title>

        <v-card-text class="d-flex flex-wrap">
          <div
            v-if="
              typeof generate_dialog_error_messages === 'object' &&
                Object.keys(generate_dialog_error_messages).length > 0
            "
            class="alert alert-danger col-12"
          >
            <ul>
              <template v-for="(error, i) in generate_dialog_error_messages">
                <span v-bind:key="i">
                  <li v-for="(message, ii) in error" v-bind:key="ii">
                    {{ message }}
                  </li>
                </span>
              </template>
            </ul>
          </div>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Начална дата:</span>
              </label>
              <div class="field-wrap">
                <datetime
                  zone="local"
                  value-zone="local"
                  type="date"
                  v-model="generateInvoiceDialog.startDate"
                  input-class="form-control"
                ></datetime>
              </div>
              <div v-if="$v.invoice.$error">
                <div class="text-danger" v-if="!$v.invoice.date.required">
                  {{ $t("VALIDATIONS.REQUIRED") }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="form-group">
              <label for="">
                <span> Крайна дата:</span>
              </label>
              <div class="field-wrap">
                <datetime
                  zone="local"
                  value-zone="local"
                  type="date"
                  v-model="generateInvoiceDialog.endDate"
                  input-class="form-control"
                ></datetime>
              </div>
              <div v-if="$v.invoice.$error">
                <div class="text-danger" v-if="!$v.invoice.date.required">
                  {{ $t("VALIDATIONS.REQUIRED") }}
                </div>
              </div>
            </div>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="generateInvoice()"> Изпрати </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_ALL_CLIENTS } from "@/store/clients.module";
import { GET_ROUTEMAPS_BY_PERIOD } from "@/store/routemaps.module";
import {
  GET_FREE_ROUTEMAPS,
  GET_INVOICE,
  SAVE_INVOICE,
  UPDATE_INVOICE
} from "@/store/invoices.module";

import { GET_DATA } from "@/store/formselect.module";

import { mapGetters, mapState } from "vuex";

import { required } from "vuelidate/lib/validators";

import _ from "lodash";

function initProductData() {
  return {
    productTypeId: null,
    price: 0.0
  };
}

export default {
  name: "createUpdate",

  components: {},
  computed: {
    ...mapGetters(["isLoadingInvoices"]),
    ...mapState({
      errors: state => state.auth.errors
    }),

    isEdit: function() {
      if (typeof this.$route.params.id !== "undefined") {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      generate_dialog_error_messages: [],
      error_messages: [],
      model: "invoice",
      tab: 0,
      vendfors: [],
      invoice: {
        clientId: null,
        date: null,
        number: null,
        details: []
      },
      generateInvoiceDialog: {
        display: false,
        startDate: null,
        endDate: null
      },
      newProduct: initProductData(),
      materials: [],
      clients: [],
      productTypes: [],
      freeRouteMaps: [],
      fullFreeRouteMaps: []
    };
  },
  validations() {
    let validations = {
      invoice: {
        clientId: {
          required
        },
        date: {
          required
        },
        number: {
          required
        }
      },
      newProduct: {
        productTypeId: {
          required
        },
        price: {
          required
        }
      }
    };

    if (this.newProduct.productTypeId == 1) {
      validations.newProduct.quantity = { required };
      delete validations.newProduct.routeMapId;
    }

    if (this.newProduct.productTypeId == 2) {
      validations.newProduct.routeMapId = { required };
      delete validations.newProduct.quantity;
    }

    return validations;
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        {
          title: "Фактура",
          route: "/invoices"
        },
        { title: current_page }
      ]);
    }, 300);

    this.getProductTypes();

    this.getClients().then(() => {
      if (typeof this.$route.params.id !== "undefined") {
        vm.getData();
      }
    });
  },
  methods: {
    getPrice(quantity, priceOver, priceUnder) {
      if (quantity > 5000) {
        return priceOver;
      } else {
        return priceUnder;
      }
    },
    changeRouteMap(routeMapId, product) {
      let routeMap = _.find(this.fullFreeRouteMaps, rm => rm.id === routeMapId);

      product.price = this.getPrice(
        routeMap.created_quantity,
        routeMap.price_over,
        routeMap.price_under
      );
    },
    generateInvoice() {
      let vm = this;

      const payload = {
        clientId: vm.invoice.clientId,
        payload: vm.generateInvoiceDialog
      };

      vm.$store
        .dispatch(GET_ROUTEMAPS_BY_PERIOD, payload)
        .then(data => {
          vm.$nextTick(function() {
            let routeMaps = data.data;

            if (routeMaps.length > 0) {
              vm.invoice.details = [];

              for (let routeMap of routeMaps) {
                vm.newProduct.productTypeId = 2;
                vm.newProduct.routeMapId = routeMap.id;
                vm.newProduct.price = vm.getPrice(
                  routeMap.created_quantity,
                  routeMap.product.price_over,
                  routeMap.product.price_under
                );

                vm.addNewProduct();
              }

              vm.generateInvoiceDialog.startDate = null;
              vm.generateInvoiceDialog.endDate = null;

              vm.generateInvoiceDialog.display = false;
            } else {
              vm.generate_dialog_error_messages = {
                period: ["Няма намерени резултати за този период"]
              };
            }
          });
        })
        .catch(response => {
          vm.generate_dialog_error_messages = response.data.errors;
        });
    },
    showGenerateInvoiceDialog() {
      this.generateInvoiceDialog.display = true;
    },
    getClients() {
      let vm = this;
      this.error_messages = [];
      return this.$store
        .dispatch(GET_ALL_CLIENTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.clients = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
          }
        });
    },

    getProductTypes: function() {
      let vm = this;
      let payload = {
        invoiceProductTypes: {
          fields: ["id", "name"],
          search: ""
        }
      };

      this.error_messages = [];

      vm.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.productTypes = data.data.invoiceProductTypes;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },

    fetchRoutemaps() {
      let vm = this;

      this.error_messages = [];

      let payload = {
        clientId: vm.invoice.clientId
      };

      if (typeof this.$route.params.id !== "undefined") {
        payload.invoiceId = this.$route.params.id;
      }

      vm.$store
        .dispatch(GET_FREE_ROUTEMAPS, payload)
        .then(data => {
          vm.freeRouteMaps = data.data;
          vm.fullFreeRouteMaps = data.data;

          vm.filterRouteMaps();
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },

    filterRouteMaps() {
      let vm = this;

      vm.freeRouteMaps = [...vm.fullFreeRouteMaps];

      this.invoice.details.forEach(product => {
        if (product.productTypeId === 2) {
          vm.freeRouteMaps = vm.freeRouteMaps.filter(
            e => e.id !== product.routeMapId
          );
        }
      });

      // Append selected routeMap for each product
      this.invoice.details.forEach(product => {
        const selectedRouteMap = vm.fullFreeRouteMaps.find(
          e => e.id === product.routeMapId
        );

        product.freeRouteMaps = [...vm.freeRouteMaps];

        product.freeRouteMaps.push(selectedRouteMap);
      });
    },

    handleProductTypeChange(product) {
      // Repackaging
      if (product.productTypeId == 1) {
        delete product.routeMapId;
      } else {
        delete product.quantity;
      }
    },

    deleteProduct: function(i) {
      let vm = this;

      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "За да завършите това действие, трябва да запишете промените!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.invoice.details.splice(i, 1);
        },
        onCancel() {}
      });
    },

    save: function() {
      let vm = this;

      vm.error_messages = [];

      vm.$v.invoice.$touch();

      if (this.$v.invoice.$error) {
        vm.$scrollTo("html", 420, {
          offset: -300
        });
        return;
      }

      var action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_INVOICE
          : SAVE_INVOICE;

      vm.$store
        .dispatch(action, { payload: vm.invoice })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$router.push({ name: "invoicesList" });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "invoicesList" });
          }
        });
    },
    getData: function() {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      this.$store
        .dispatch(GET_INVOICE, id)
        .then(data => {
          vm.invoice = data.data;
          vm.fetchRoutemaps();

          if (vm.invoice.clientIsArchived) {
            vm.clients.push({
              id: vm.invoice.clientId,
              name: vm.invoice.clientName + " (Архивиран)"
            });
          }
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "invoicesList" });
          }
        });
    },
    addNewProduct() {
      let vm = this;

      this.$v.newProduct.$touch();

      if (this.$v.newProduct.$error) {
        return;
      }

      vm.$set(vm.invoice.details, vm.invoice.details.length, vm.newProduct);

      vm.filterRouteMaps();

      vm.$v.$reset();

      vm.newProduct = initProductData();
    }
  }
};
</script>
